import React from "react"
import data from "../../data/members/memberData.json"

export default function hoe() {
  return (
    <div className="memberCardContainer">
      {data.map(member => (
        <div>
          <img src={member.avatar} />
          <h1>{member.name}</h1>
          <h2>{member.post}</h2>
          <h3>{member.description}</h3>
        </div>
      ))}
    </div>
  )
}
